<script setup>
import { useUserStore } from '@/stores/user.js'
import { RouterView } from 'vue-router'
import AppTopbar from './AppTopbar.vue'
// import { useMeta } from 'vue-meta'

const store = useUserStore()

// State synchronization with localStorage. It can also be done by using a
// plugin like "pinia-plugin-persistedstate" but can be implemented easily with
// Pinia's subscribtions and localStorage's events.

/** Save state to localStorage on any state mutation in Pinia store */
store.$subscribe((_ /*mutation*/, state) => {
  localStorage.setItem('userStore', JSON.stringify(state))
  console.log('estado guardado')
})

/** Load state from localStorage to Pinia store if there is anything saved */
function loadState() {
  const storageData = localStorage.getItem('userStore')
  console.log('Searching previous state in localStorage')
  if (storageData) {
    const state = JSON.parse(storageData)
    console.log('State found in localStorage, loading:', state)
    store.$state = state
  }
}

// load state if it exists on start of application or page reload
loadState()

// load state whenever data localStorage changes from another window or tab
window.addEventListener('storage', (event) => {
  if (event.key === 'userStore') {
    loadState()
  }
})

//useMeta({
//  title: 'ttt',
//})
</script>

<template>
  <header>
    <app-topbar></app-topbar>
  </header>

  <!-- sidebar, if needed -->

  <main class="flex-1 m-auto w-11 py-3">
    <RouterView />
  </main>

  <footer class="surface-600 text-200 p-4">
    <!-- <RouterLink to="/about">Sobre esta aplicación</RouterLink> -->
    <h4 class="mt-0">
      Estrategia de Energía, Clima y Aire de la Comunidad de Madrid 2023-2030.
    </h4>
    <span class="mr-4">EECAM - 2025. Versión 0.3.4 - 20250123</span>
    <p>
      Aplicación para el seguimiento de los indicadores a la Estrategia de
      Energía, Clima y Aire de la Comunidad de Madrid 2023-2030.
    </p>
  </footer>

  <Toast />
</template>

<style scoped></style>
